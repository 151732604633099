import React, { useEffect, useState } from 'react'
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridRenderCellParams,
  GridRowId,
  GridRowModel,
  GridToolbar
} from '@mui/x-data-grid'
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import TimeIcon from '@mui/icons-material/Timer'
import SearchUserIcon from '@mui/icons-material/SearchTwoTone'
import { useSelector } from 'react-redux'
import { Stack, Box, Grid, Button, ClickAwayListener, Typography, Tooltip, IconButton } from '@mui/material'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import {
  CREATE_USER_TRIAL,
  LIST_USER_TRIALS_BY_USERNAME,
  REMOVE_USER_TRIAL,
  EXTEND_USER_TRIAL
} from '../graphql/queries/trialQueries'
import { convertAppId, convertTimeStamp } from '../data/Helpers'
import { RootState } from '../store'
import ByShipToUserSelectModal from '../components/ByShipToUserSelectModal'
import ExtendTrialDialog from '../components/ExtendTrialDialog'
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog'
import TrialsMenuComponent from '../components/TrialsMenuComponent'

// Type userTrial for row data.
type userTrial = {
  appId: string
  trialCreationDate: number
  trialExpirationDate: number
  lastLogin: number
  trialExtensions: number
}
interface ITrialsViewProps {
  writeAccessRole: string[]
}
const TrialsView: React.FC<ITrialsViewProps> = ({ writeAccessRole }) => {
  document.title = 'Gems Admin Portal - Trials'
  const dashHeight = '720px'
  const accessToken = useSelector((state: RootState) => state.accessToken)
  const rolesStore = useSelector((state: RootState) => state.rootStore.rolesStore)
  const [readOnlyAccess, setReadOnlyAccess] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [rows, setRows] = useState<GridRowModel[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [selectedRow, setSelectedRow] = useState<GridRowId[]>([])
  const [userSelected, setUserSelected] = useState<boolean>(false)
  const regex = /([\w-]+)/
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState<boolean>(false)
  const [applicationDeletionName, setApplicationDeletionName] = useState<string>('')
  const [extendDialogOpen, setExtendDialogOpen] = useState<boolean>(false)
  const [userOwnedTrials, setUserOwnedTrials] = useState<Array<string>>([])

  const handleExtendDialogOpen = () => {
    setExtendDialogOpen(true)
  }
  const handleExtendDialogClose = () => {
    setExtendDialogOpen(false)
  }
  const handleDeleteDialogClose = () => {
    setDeleteConfirmationOpen(false)
  }

  const handleDeleteDialogOpen = () => {
    setDeleteConfirmationOpen(true)
  }

  // QUERY: userTrials data.
  const [listUserTrials, { loading, data, error }] = useLazyQuery(LIST_USER_TRIALS_BY_USERNAME, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    },
    fetchPolicy: 'network-only'
  })

  // MUTATION: remove a trial.
  const [removeTrial] = useMutation(REMOVE_USER_TRIAL, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    }
  })

  // MUTATION: add a trial.
  const [addTrial] = useMutation(CREATE_USER_TRIAL, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    }
  })

  // MUTATION: extend trial
  const [extendTrial] = useMutation(EXTEND_USER_TRIAL, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    onError: (err: ApolloError) => {
      toast.error(err.message, { theme: 'colored' })
    }
  })

  const renderRemoveTrialButton = (params: GridRenderCellParams) => (
    <Tooltip title='Remove Trial'>
      <IconButton
        color='error'
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event?.stopPropagation()
          setApplicationDeletionName(params.row.col1)
          handleDeleteDialogOpen()
          console.log(params)
          console.log(params.value)
        }}
        disabled={readOnlyAccess}
      >
        <DeleteOutlinedIcon />
      </IconButton>
    </Tooltip>
  )

  const renderAddTrialTimeButton = (params: GridRenderCellParams) => (
    <strong>
      <Button
        size='small'
        variant='contained'
        fullWidth
        startIcon={<TimeIcon />}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event?.stopPropagation()
          setApplicationDeletionName(params.row.col1)
          handleExtendDialogOpen()
        }}
        disabled={readOnlyAccess}
      >
        Add Time
      </Button>
    </strong>
  )

  // GRID COLUMNS
  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Trials', width: 200 },
    { field: 'col2', headerName: 'Created' },
    { field: 'col3', headerName: 'Expires' },
    { field: 'col4', headerName: 'Last Login' },
    { field: 'col5', headerName: 'Extensions' },
    { field: 'col6', headerName: 'Add Time', renderCell: renderAddTrialTimeButton, width: 150 },
    { field: 'col7', headerName: 'Remove Trial', renderCell: renderRemoveTrialButton, width: 150 }
  ]

  // // GRID ROWS (testing)
  const handleDataGrid = () => {
    console.log('setting rows:')
    listUserTrials({
      variables: {
        username: searchTerm
      }
    })
    console.log('setting rows: rows set')
  }

  const handleSearch = (): void => {
    const formattedSearch = searchTerm.trim().toLowerCase()
    console.log(`searchTerm: ${formattedSearch}`)
    if (regex.test(formattedSearch)) {
      listUserTrials({
        variables: {
          username: formattedSearch
        }
      })
      setUserSelected(true)
    } else {
      setSearchTerm('')
      setUserSelected(false)
      setRows([])
    }
  }

  const handleShipToUserSearch = (value: string): void => {
    setSearchTerm(value)
    const formattedSearch = value.trim().toLowerCase()
    console.log(`searchTerm: ${formattedSearch}`)
    if (regex.test(formattedSearch)) {
      listUserTrials({
        variables: {
          username: formattedSearch
        }
      })
      setUserSelected(true)
    } else {
      setSearchTerm('')
      setUserSelected(false)
      setRows([])
    }
  }

  // HANDLER: HANDLE CHANGE
  const handleChange = (value: string) => {
    setSearchTerm(value)
    setUserSelected(false)
    setRows([])
  }

  const refreshDataGrid = async () => {
    try {
      setIsLoading(true)
      await listUserTrials({
        variables: {
          username: searchTerm
        }
      })
      handleDataGrid()
      setIsLoading(false)
    } catch (e) {
      console.error(e)
    }
  }

  // HANDLER: REMOVE TRIAL
  const handleRemoveTrial = async (appName: string) => {
    await removeTrial({
      variables: {
        username: searchTerm,
        appId: convertAppId(appName)
      }
    })
    refreshDataGrid()
  }

  // HANLDER: ADD TRIAL
  const handleAddTrial = async (appId: string) => {
    await addTrial({
      variables: {
        username: searchTerm,
        applicationId: appId,
        trialDays: 14
      }
    })
    refreshDataGrid()
    console.log(`handleAddTrial: \n ${appId} trial added`)
  }

  // Handle Extend Trial
  const handleExtendTrial = async (username: string, applicationId: string, days: number) => {
    try {
      await extendTrial({
        variables: {
          username,
          applicationId,
          trialDays: days
        }
      })
      refreshDataGrid()
    } catch (e) {
      console.error(e)
    }
  }

  // USE EFFECT
  useEffect(() => {
    // check user roles to see if they have read only access
    const roles = rolesStore
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < roles.length; i++) {
      const userRole = roles[i]
      const found = writeAccessRole.findIndex((element) => element === userRole)
      if (found > -1) {
        console.log('write access found')
        setReadOnlyAccess(false)
        break
      }
    }
    if (loading) {
      setIsLoading(true)
    }
    if (error) {
      console.log(`useEffect: has error: ${error.message}`)
      setIsLoading(false)
    }
    if (data) {
      // sort the list so names appear in similar places on add / removal
      // the map, gets rid of the readonly collection issues
      const sortedDataList = data.listUserTrials.items
        .map((i: userTrial) => i)
        .sort((a: userTrial, b: userTrial) => a.appId.localeCompare(b.appId))

      setUserOwnedTrials(sortedDataList.map((e: userTrial) => convertAppId(e.appId).toLowerCase()))
      setRows(
        sortedDataList.map((element: userTrial, index: number) => ({
          id: index + 1,
          col1: convertAppId(element.appId),
          col2: convertTimeStamp(element.trialCreationDate),
          col3: convertTimeStamp(element.trialExpirationDate),
          col4: convertTimeStamp(element.lastLogin),
          col5: element.trialExtensions,
          col6: element.appId,
          col7: element.appId
        }))
      )
      console.log('useEffect: has data: setting rows: rows set')
      setIsLoading(false)
    }
  }, [data, loading, error, rolesStore, writeAccessRole])

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Stack alignItems='center'>
        <SearchUserIcon fontSize='large' color='primary' />
        <Typography color='secondary' variant='overline'>
          Search for a valid username to load results.
        </Typography>
      </Stack>
    </GridOverlay>
  )

  return (
    <>
      <Grid container justifyContent='center'>
        <DeleteConfirmationDialog
          handleDeleteDialogClose={handleDeleteDialogClose}
          handleRemoveTrial={handleRemoveTrial}
          deleteConfirmationDialogOpen={deleteConfirmationOpen}
          userName={searchTerm}
          trialApplication={applicationDeletionName}
        />
        <ExtendTrialDialog
          handleExtendDialogClose={handleExtendDialogClose}
          extendDialogOpen={extendDialogOpen}
          handleExtendTrial={handleExtendTrial}
          userName={searchTerm}
          trialApplication={applicationDeletionName}
        />
        {/* DASH */}
        <Grid item xs={12} style={{ height: dashHeight }}>
          <div style={{ width: '100%' }}>
            <ByShipToUserSelectModal handleShipToUserSearch={handleShipToUserSearch} />
            <TrialsMenuComponent
              searchTerm={searchTerm}
              handleAddTrial={handleAddTrial}
              // handleRemoveTrial={handleRemoveTrial}
              handleChange={handleChange}
              handleSearch={handleSearch}
              // selectedRow={selectedRow}
              readOnlyAccess={readOnlyAccess}
              isLoading={isLoading}
              userSelected={userSelected}
              ownedUserTrials={userOwnedTrials}
            />
            <Box height={350}>
              <ClickAwayListener onClickAway={() => setSelectedRow([])}>
                <DataGrid
                  loading={isLoading}
                  rows={rows}
                  columns={columns}
                  rowSelectionModel={selectedRow}
                  slots={{ toolbar: GridToolbar, noRowsOverlay: CustomNoRowsOverlay }}
                  hideFooter
                  disableColumnFilter
                />
              </ClickAwayListener>
            </Box>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default TrialsView
