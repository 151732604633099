import { ReactElement } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import ForbiddenView from '../views/ForbiddenView'
import PublicRoute from './utils/PublicRoute'
import LoginView from '../views/LoginView'
import RoleRoute from './utils/RoleRoute'
import ProtectedRoute from './utils/ProtectedRoute'
import DashboardView from '../views/DashboardView'
import UsersByShipToView from '../views/ShipToView'
import GemsListView from '../views/GemsView'
import TrialsByUsernameView from '../views/TrialsView'
import AccountsView from '../views/AccountsView'
import EntitlementsView from '../views/EntitlementsView'
import PageWithSubTitleLayout from '../layouts/PageWithSubTitleLayout'
import ManageAccountEntitlementsView from '../views/ManageAccountEntitlementsView'
import UserSoftwareAccess from '../views/UserSoftwareAccess'
import ManageGemsUsersView from '../views/ManageGemsUsersView'
import UserFeatureFlags from '../views/ManageUserFeatureFlags'
import { gemsUserGroups } from '../constants'

const AppRoutes = (): ReactElement => {
  return (
    <Routes>
      <Route
        path='/'
        element={
          <PublicRoute>
            <Navigate to='/login' />
          </PublicRoute>
        }
      />
      <Route
        path='/login'
        element={
          <PublicRoute>
            <LoginView />
          </PublicRoute>
        }
      />
      <Route
        path='/dashboard'
        element={
          <ProtectedRoute>
            <DashboardView />
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/account/entitlements'
        element={
          <ProtectedRoute>
            <RoleRoute requiredRole={[gemsUserGroups.SUPPORT, gemsUserGroups.ADMIN, gemsUserGroups.SUPPORTREADONLY]}>
              <PageWithSubTitleLayout subTitle='Manage Account Entitlements'>
                <ManageAccountEntitlementsView writeAccessRole={[gemsUserGroups.SUPPORT, gemsUserGroups.ADMIN]} />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/user/trials'
        element={
          <ProtectedRoute>
            <RoleRoute requiredRole={[gemsUserGroups.SUPPORT, gemsUserGroups.ADMIN, gemsUserGroups.SUPPORTREADONLY]}>
              <PageWithSubTitleLayout subTitle='Manage User Trials'>
                <TrialsByUsernameView writeAccessRole={[gemsUserGroups.SUPPORT, gemsUserGroups.ADMIN]} />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/shiptoreport'
        element={
          <ProtectedRoute>
            <RoleRoute
              requiredRole={[
                gemsUserGroups.SUPPORT,
                gemsUserGroups.SUPPORTREADONLY,
                gemsUserGroups.DEV,
                gemsUserGroups.OPS,
                gemsUserGroups.ADMIN
              ]}
            >
              <UsersByShipToView />
            </RoleRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/accountsreport'
        element={
          <ProtectedRoute>
            <RoleRoute
              requiredRole={[
                gemsUserGroups.SUPPORT,
                gemsUserGroups.SUPPORTREADONLY,
                gemsUserGroups.DEV,
                gemsUserGroups.OPS,
                gemsUserGroups.ADMIN
              ]}
            >
              <AccountsView />
            </RoleRoute>
          </ProtectedRoute>
        }
      />

      <Route
        path='/dashboard/gemsreport'
        element={
          <ProtectedRoute>
            <RoleRoute
              requiredRole={[
                gemsUserGroups.SUPPORT,
                gemsUserGroups.SUPPORTREADONLY,
                gemsUserGroups.DEV,
                gemsUserGroups.OPS,
                gemsUserGroups.ADMIN
              ]}
            >
              <GemsListView />
            </RoleRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/entitlements'
        element={
          <ProtectedRoute>
            <RoleRoute requiredRole={[gemsUserGroups.SUPPORT, gemsUserGroups.SUPPORTREADONLY, gemsUserGroups.ADMIN]}>
              <EntitlementsView />
            </RoleRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/user/softwareaccess'
        element={
          <ProtectedRoute>
            <RoleRoute requiredRole={[gemsUserGroups.DEV, gemsUserGroups.ADMIN]}>
              <PageWithSubTitleLayout subTitle='Manage User Feature Access'>
                <UserSoftwareAccess />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/user/featureflags'
        element={
          <ProtectedRoute>
            <RoleRoute requiredRole={[gemsUserGroups.DEV, gemsUserGroups.ADMIN]}>
              <PageWithSubTitleLayout subTitle='Manage User Feature Flags'>
                <UserFeatureFlags />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        }
      />
      <Route
        path='/dashboard/admin/users'
        element={
          <ProtectedRoute>
            <RoleRoute requiredRole={[gemsUserGroups.ADMIN]}>
              <PageWithSubTitleLayout subTitle='Manage GEMS Users'>
                <ManageGemsUsersView />
              </PageWithSubTitleLayout>
            </RoleRoute>
          </ProtectedRoute>
        }
      />
      <Route path='/forbidden' element={<ForbiddenView />} />
    </Routes>
  )
}

export default AppRoutes
