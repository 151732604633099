import * as React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DiamondIcon from '@mui/icons-material/DiamondTwoTone'
import { Drawer, Box, ListSubheader, Stack, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { MenuConfig } from '../data/MenuConfig'
import UserInfo from './UserInfo'
import { RootState } from '../store'

const drawerWidth = 300
interface AppMenu {
  children: React.ReactNode
}

const AppMenu: React.FC<AppMenu> = ({ children }: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isAuthenticated = useSelector((state: RootState) => state.isAuthenticated)
  return (
    <Box>
      {isAuthenticated && (
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              background: '#222'
            }
          }}
          variant='permanent'
          anchor='left'
        >
          <Stack px={4} py={2} direction='row' gap={2} alignItems='center'>
            <DiamondIcon fontSize='large' color='primary' />
            <Typography variant='overline' fontSize={16}>
              GEMS Admin Tool
            </Typography>
          </Stack>
          <Stack height='100%' justifyContent='space-between'>
            <List dense sx={{ px: 2 }}>
              {MenuConfig.map((element) => {
                switch (element.type) {
                  case 'link':
                    return (
                      <ListItem
                        button
                        key={element.name}
                        onClick={() => navigate(`/${element.path}`)}
                        sx={{
                          borderRadius: 1,
                          backgroundColor: location.pathname.substring(1) === element.path ? 'primary.main' : ''
                        }}
                      >
                        <ListItemIcon sx={{ minWidth: 40 }}>{element.icon}</ListItemIcon>
                        <ListItemText
                          primary={element.name}
                          primaryTypographyProps={{
                            variant: 'body2'
                          }}
                        />
                      </ListItem>
                    )
                  case 'divider':
                    return (
                      <ListSubheader key={element.name} sx={{ background: 'transparent' }}>
                        <Typography sx={{ color: 'info.main' }} variant='overline'>
                          {element.name}
                        </Typography>
                      </ListSubheader>
                    )
                  default:
                    return <></>
                }
              })}
            </List>
            <UserInfo />
          </Stack>
        </Drawer>
      )}
      <Box style={{ marginLeft: isAuthenticated ? drawerWidth : 0 }}>{children}</Box>
    </Box>
  )
}

export default AppMenu
