export const localStorageKeys = {
  username: 'USERNAME_V',
  refreshToken: 'RTOKEN_V',
  accessToken: 'ATOKEN_V'
}
export const validRoles = {
  USERSREAD: 'USERSREAD',
  USERSWRITE: 'USERSWRITE',
  ACCOUNTSREAD: 'ACCOUNTSREAD',
  ACCOUNTSWRITE: 'ACCOUNTSWRITE',
  ADMINFULLACCESS: 'ADMINFULLACCESS', // Allows modifing admin tool user roles
  ADMINBASICACCESS: 'ADMINBASICACCESS' // all basic roles, minus super admin stuff
}
export enum gemsUserGroups {
  ADMIN = 'ADMIN',
  OPS = 'OPS',
  DEV = 'DEV',
  SUPPORT = 'SUPPORT',
  SUPPORTREADONLY = 'SUPPORTREADONLY'
}
