import React, { useEffect, useState } from 'react'
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid'
import { toast } from 'react-toastify'
import { ApolloError, useLazyQuery } from '@apollo/client'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Box, Grid, CircularProgress, IconButton, LinearProgress } from '@mui/material'
import { useSelector } from 'react-redux'
import { LIST_GEMS_USERS } from '../graphql/queries/gemsQueries'
import { convertTimeStamp } from '../data/Helpers'
import { RootState } from '../store'

type gemsUser = {
  userID: string
  username: string
  shipTo: string
  billTo: string
  email: string
  firstName: string
  lastName: string
  lastLogin: number
  shipTos: string
}

const GemsView = () => {
  document.title = 'Gems Admin Portal - Dashboard'
  const dashHeight = '720px'
  // searchTerm is passed down from SearchComponent > ModalComponent > DataGridComponent
  const accessToken = useSelector((state: RootState) => state.accessToken)
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const rowHeight = 40

  const [listGemsUsers, { called, loading, data, error }] = useLazyQuery(LIST_GEMS_USERS, {
    context: {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    },
    fetchPolicy: 'network-only',
    onError: (err: ApolloError) => {
      console.error(`Error on listGemsUsers query: ${err.message}`)
      setRows([])
    }
  })

  useEffect(() => {
    console.log('useEffect: ')
    if (data && data.listGemsUsers) {
      console.log('useEffect: has data')
      setRows(
        data.listGemsUsers.items.map((element: gemsUser, index: number) => ({
          id: index + 1,
          col2: element.username,
          col3: element.firstName,
          col4: element.lastName,
          col5: convertTimeStamp(element.lastLogin),
          col6: element.shipTo,
          col8: element.email,
          col9: convertTimeStamp(element.lastLogin),
          col10: element.shipTos
        }))
      )
      setIsLoading(false)
    }
    if (error) {
      console.log(`useEffect: has error: ${error.message}`)
      setRows([])
      setIsLoading(false)
      toast.error(error.message, { theme: 'colored' })
    }
    if (called && loading) {
      console.log('useEffect: loading')
      setIsLoading(true)
    }
  }, [data, error, called, loading, setIsLoading])

  const handleSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    event.preventDefault()
    listGemsUsers()
    console.log('handling search...')
  }

  // Define columns of datagrid
  const columns: GridColDef[] = [
    { field: 'col2', headerName: 'Username', width: 150 },
    { field: 'col3', headerName: 'First Name', width: 150 },
    { field: 'col4', headerName: 'Last Name', width: 150 },
    { field: 'col5', headerName: 'Last Login', width: 150 },
    { field: 'col6', headerName: 'ShipTo', width: 150 },
    { field: 'col8', headerName: 'Email', width: 250 },
    { field: 'col9', headerName: 'Last Login', width: 150 },
    { field: 'col10', headerName: 'ShipTos', width: 300 }
  ]

  return (
    <>
      <Grid container justifyContent='center'>
        {/* DASH */}
        <Grid item xs={10} style={{ height: dashHeight }}>
          <div style={{ height: '85%', width: '100%' }}>
            <Box marginBottom='16px' justifyContent='center' alignItems='center'>
              <IconButton type='submit' color='success' onClick={(e) => handleSearch(e)}>
                {isLoading ? <CircularProgress /> : <AutorenewIcon />}
              </IconButton>
            </Box>
            {isLoading && <LinearProgress />}
            <DataGrid loading={isLoading} rows={rows as GridRowsProp} columns={columns} rowHeight={rowHeight} />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default GemsView
